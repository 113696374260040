export type SEOMetaContent = {
    path: string;
    title: string;
    description: string;
    keywords: string;
}

export const SEOMetaContents: SEOMetaContent[] = [
	{
		path: '/',
		title: 'Textualyze | Automatic Video Captions and Translations',
		description: 'Automatic Video Captions and Translations',
		keywords: 'textualyze, video captions, video translation, TikTok captions, Automatic Subtitles, Translate Twitter Video, Translate Youtube Video, Translate TikTok Video'
	},
	{
		path: '/login',
		title: 'Textualyze | Login',
		description: 'Login to your account to start adding captions to your videos',
		keywords: 'textualyze, login, video captions, video translation, TikTok captions'
	},
	{
		path: '/signup',
		title: 'Textualyze | Sign up',
		description: 'The ultimate video captions service that offers unparalleled speed and simplicity',
		keywords: 'textualyze, signup, video captions, video translation, TikTok captions'
	},
	{
		path: '/pricing',
		title: 'Textualyze | Pricing',
		description: 'Simple, transparent pricing. Choose the plan that fits your needs',
		keywords: 'textualyze, pricing, video captions, video translation, TikTok captions'
	},
	{
		path: '/blog',
		title: 'Textualyze | Blog',
		description: 'Insights on Video Captions & Translating Media Content',
		keywords: 'textualyze, blog, latest news, cutting-edge, technology, video translation'
	},
	{
		path: '/our-team',
		title: 'Textualyze | Our Team',
		description: 'Learn more about our team',
		keywords: 'textualyze, our team, teamwork, project management'
	},
	{
		path: '/terms-and-privacy',
		title: 'Terms and privacy',
		description: 'Textualyze\'s Terms, Privacy Policy and Cookie Policy',
		keywords: 'textualyze, terms and privacy, video captions, video translation, TikTok captions'
	}
];
