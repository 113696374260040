import {CSSProperties} from 'react';

export const appBarHeight = 80;
export const saleBannerHeight = 75;

export const exportDropdownZIndex = 10007;
export const emojiPickerZIndex = 10006;
export const dialogZIndex = 10005;
export const dashboardDropdownZIndex = 10004;
export const transcriptionVideoCloseFabZIndex = 10003;
export const transcriptionVideoZIndex = 10002;
export const drawerOverlayZIndex = 9999;

export const minFontSize = 12;
export const maxFontSize = 160;

export const userSelectNone: CSSProperties = {
	WebkitTouchCallout: 'none',
	WebkitUserSelect: 'none',
	KhtmlUserSelect: 'none',
	MozUserSelect: 'none',
	msUserSelect: 'none',
	userSelect: 'none',
};

export const discordLink = 'https://discord.gg/u3wEh6hprQ';