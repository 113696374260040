import {Box, SxProps, Theme} from '@mui/material';
import React from 'react';

type OutlookSVGProps = {
    sx?: SxProps<Theme>;
};

export const OutlookSVG: React.FC<OutlookSVGProps> = ({sx}) => {
	return (
		<Box display="flex" alignItems="center" sx={sx}>
			<svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"
				 className="sc-bgqQcB gvEExp">
				<g clipPath="url(#clip0_18708_78750)">
					<path
						d="M24.2497 13.0499C24.2503 12.959 24.2271 12.8694 24.1824 12.7899C24.1376 12.7104 24.0727 12.6437 23.9941 12.5961H23.9913L23.9812 12.5906L15.665 7.73963C15.629 7.71573 15.5918 7.69388 15.5533 7.67419C15.3951 7.59374 15.2196 7.55176 15.0415 7.55176C14.8634 7.55176 14.6879 7.59374 14.5297 7.67419C14.4912 7.69388 14.454 7.71573 14.4181 7.73963L6.10181 12.5906L6.09177 12.5961C5.8376 12.7519 5.75968 13.0812 5.91774 13.3317C5.96431 13.4055 6.02845 13.4669 6.1046 13.5108L14.4209 18.3618C14.4569 18.3855 14.4942 18.4073 14.5325 18.4272C14.6908 18.5077 14.8663 18.5497 15.0443 18.5497C15.2224 18.5497 15.3979 18.5077 15.5561 18.4272C15.5945 18.4073 15.6317 18.3855 15.6678 18.3618L23.984 13.5108C24.0657 13.4639 24.1332 13.3965 24.1799 13.3156C24.2265 13.2346 24.2506 13.143 24.2497 13.0499Z"
						fill="#0A2767"></path>
					<path
						d="M7.07144 9.81104H12.5289V14.7407H7.07144V9.81104ZM23.1336 4.8V2.54499C23.1467 1.98119 22.6938 1.51351 22.1217 1.5H7.95945C7.38731 1.51351 6.93444 1.98119 6.94754 2.54499V4.8L15.3196 6.99999L23.1336 4.8Z"
						fill="#0364B8"></path>
					<path d="M6.94727 4.80005H12.5287V9.75004H6.94727V4.80005Z" fill="#0078D4"></path>
					<path d="M18.1107 4.80005H12.5293V9.75004L18.1107 14.7H23.1339V9.75004L18.1107 4.80005Z"
						  fill="#28A8EA"></path>
					<path d="M12.5293 9.75H18.1107V14.7H12.5293V9.75Z" fill="#0078D4"></path>
					<path d="M12.5293 14.7H18.1107V19.6499H12.5293V14.7Z" fill="#0364B8"></path>
					<path d="M7.07129 14.7407H12.5288V19.2221H7.07129V14.7407Z" fill="#14447D"></path>
					<path d="M18.1104 14.7H23.1336V19.6499H18.1104V14.7Z" fill="#0078D4"></path>
					<path
						d="M23.994 13.4807L23.9834 13.4862L15.6671 18.0952C15.6309 18.1172 15.594 18.1381 15.5555 18.1568C15.4143 18.2231 15.2608 18.2605 15.1045 18.2668L14.6502 18.005C14.6118 17.986 14.5745 17.9649 14.5386 17.9417L6.11068 13.2018H6.10677L5.83105 13.05V22.3802C5.83535 23.0027 6.35079 23.504 6.98249 23.5H23.1161C23.1255 23.5 23.1339 23.4956 23.144 23.4956C23.2774 23.4872 23.4089 23.4602 23.5347 23.4153C23.589 23.3927 23.6414 23.3658 23.6915 23.335C23.7289 23.3141 23.7931 23.2685 23.7931 23.2685C23.9341 23.1657 24.0488 23.0318 24.128 22.8776C24.2073 22.7234 24.2489 22.5531 24.2496 22.3803V13.05C24.2495 13.1377 24.2258 13.2238 24.1809 13.2994C24.136 13.3751 24.0715 13.4377 23.994 13.4807Z"
						fill="url(#paint0_linear_18708_78750)"></path>
					<path opacity="0.5"
						  d="M23.8037 13.0132V13.5852L15.1079 19.4851L6.10506 13.2057C6.10506 13.2043 6.10447 13.2029 6.10343 13.2018C6.10238 13.2008 6.10096 13.2002 6.09948 13.2002L5.27344 12.7107V12.2982L5.6139 12.2927L6.3339 12.6997L6.35063 12.7052L6.41203 12.7437C6.41203 12.7437 14.8734 17.5012 14.8957 17.5122L15.2195 17.6992C15.2474 17.6882 15.2753 17.6772 15.3088 17.6662C15.3255 17.6552 23.7087 13.0077 23.7087 13.0077L23.8037 13.0132Z"
						  fill="#0A2767"></path>
					<path
						d="M23.994 13.4807L23.9834 13.4867L15.6671 18.0957C15.6308 18.1177 15.594 18.1386 15.5555 18.1573C15.3961 18.2341 15.2211 18.2739 15.0437 18.2739C14.8663 18.2739 14.6912 18.2341 14.5319 18.1573C14.4936 18.1387 14.4564 18.1181 14.4202 18.0957L6.10398 13.4867L6.09394 13.4807C6.0151 13.4386 5.94914 13.3764 5.90291 13.3007C5.85668 13.225 5.83187 13.1384 5.83105 13.05V22.3802C5.83505 23.0026 6.3503 23.504 6.98189 23.5H23.0988C23.7304 23.504 24.2456 23.0027 24.2496 22.3803C24.2496 22.3803 24.2496 22.3803 24.2496 22.3803V13.05C24.2495 13.1377 24.2258 13.2238 24.1809 13.2994C24.136 13.3751 24.0715 13.4376 23.994 13.4807Z"
						fill="#1490DF"></path>
					<path opacity="0.1"
						  d="M15.789 18.0276L15.6646 18.0963C15.6285 18.119 15.5912 18.1397 15.5529 18.1585C15.4158 18.2248 15.2667 18.2637 15.1143 18.2729L18.2783 21.9601L23.7978 23.2707C23.949 23.1582 24.0693 23.0101 24.1477 22.8401L15.789 18.0276Z"
						  fill="black"></path>
					<path opacity="0.05"
						  d="M16.3528 17.7151L15.6646 18.0962C15.6285 18.1189 15.5912 18.1396 15.5529 18.1584C15.4158 18.2247 15.2667 18.2636 15.1143 18.2728L16.5967 22.3004L23.7995 23.269C23.9394 23.1654 24.053 23.0312 24.1313 22.877C24.2096 22.7227 24.2503 22.5526 24.2504 22.3802V22.2597L16.3528 17.7151Z"
						  fill="black"></path>
					<path
						d="M6.99756 23.5H23.0971C23.3448 23.5013 23.5864 23.4242 23.7864 23.28L14.6496 18.0061C14.6112 17.9871 14.574 17.966 14.538 17.9429L6.11012 13.2029H6.10621L5.83106 13.05V22.3483C5.83043 22.9838 6.35269 23.4994 6.99756 23.5C6.99755 23.5 6.99755 23.5 6.99756 23.5Z"
						fill="#28A8EA"></path>
					<path opacity="0.1"
						  d="M13.645 6.90805V18.6395C13.644 19.0509 13.3902 19.4206 13.0031 19.5745C12.8832 19.6253 12.7541 19.6515 12.6236 19.6515H5.83105V6.4499H6.94734V5.8999H12.6236C13.1875 5.90201 13.6438 6.35241 13.645 6.90805Z"
						  fill="black"></path>
					<path opacity="0.2"
						  d="M13.0869 7.45814V19.1896C13.0883 19.3225 13.0597 19.454 13.0031 19.5746C12.8481 19.9511 12.4778 20.1981 12.0655 20.2H5.83105V6.45H12.0655C12.2274 6.4484 12.387 6.4882 12.5287 6.5655C12.8709 6.73536 13.0867 7.08063 13.0869 7.45814Z"
						  fill="black"></path>
					<path opacity="0.2"
						  d="M13.0869 7.45814V18.0896C13.0841 18.645 12.6291 19.0952 12.0655 19.1H5.83105V6.45H12.0655C12.2274 6.4484 12.387 6.4882 12.5287 6.5655C12.8709 6.73536 13.0867 7.08063 13.0869 7.45814Z"
						  fill="black"></path>
					<path opacity="0.2"
						  d="M12.5287 7.4581V18.0896C12.5281 18.6458 12.0718 19.0972 11.5073 19.0999H5.83105V6.44995H11.5073C12.0717 6.45025 12.529 6.90137 12.5287 7.45755C12.5287 7.45773 12.5287 7.45792 12.5287 7.4581Z"
						  fill="black"></path>
					<path
						d="M1.27307 6.44995H11.506C12.071 6.44995 12.5291 6.90132 12.5291 7.4581V17.5418C12.5291 18.0986 12.071 18.5499 11.506 18.5499H1.27307C0.708037 18.5499 0.25 18.0986 0.25 17.5418V7.4581C0.25 6.90132 0.70805 6.44995 1.27307 6.44995Z"
						fill="url(#paint1_linear_18708_78750)"></path>
					<path
						d="M3.4473 10.6783C3.69945 10.1489 4.10646 9.70591 4.61605 9.40619C5.1804 9.0878 5.82296 8.92907 6.47299 8.94748C7.07545 8.93461 7.66992 9.0851 8.19149 9.38253C8.68188 9.6707 9.07683 10.093 9.32842 10.598C9.60241 11.1546 9.73896 11.7671 9.72694 12.3855C9.74022 13.0319 9.59972 13.6723 9.3167 14.2555C9.05912 14.7786 8.65164 15.2159 8.14461 15.5134C7.60293 15.8199 6.98639 15.9744 6.36192 15.96C5.74658 15.9746 5.13899 15.8224 4.60545 15.52C4.11082 15.2314 3.71091 14.8087 3.45345 14.3023C3.17784 13.7538 3.03966 13.1482 3.05048 12.5362C3.039 11.8954 3.17465 11.2602 3.4473 10.6783ZM4.69307 13.6648C4.82751 13.9995 5.05549 14.2898 5.35056 14.5019C5.65109 14.7089 6.01097 14.8157 6.37753 14.8066C6.7679 14.8218 7.15268 14.7114 7.47372 14.492C7.76505 14.2805 7.98704 13.9895 8.11222 13.6549C8.25217 13.2813 8.32122 12.8855 8.31595 12.4873C8.32027 12.0852 8.25535 11.6854 8.12394 11.3048C8.00788 10.9611 7.79304 10.6581 7.50553 10.4325C7.19253 10.2027 6.80878 10.0862 6.41883 10.1025C6.04433 10.0929 5.6764 10.2006 5.36786 10.4099C5.0678 10.623 4.83549 10.9158 4.69809 11.2542C4.39329 12.0298 4.3917 12.8892 4.69362 13.6659L4.69307 13.6648Z"
						fill="white"></path>
					<path d="M18.1104 4.80005H23.1336V9.75004H18.1104V4.80005Z" fill="#50D9FF"></path>
				</g>
				<defs>
					<linearGradient id="paint0_linear_18708_78750" x1="15.0403" y1="13.05" x2="15.0403" y2="23.5001"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#35B8F1"></stop>
						<stop offset="1" stopColor="#28A8EA"></stop>
					</linearGradient>
					<linearGradient id="paint1_linear_18708_78750" x1="2.38312" y1="5.6622" x2="10.2207" y2="19.4374"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#1784D9"></stop>
						<stop offset="0.5" stopColor="#107AD5"></stop>
						<stop offset="1" stopColor="#0A63C9"></stop>
					</linearGradient>
					<clipPath id="clip0_18708_78750">
						<rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"></rect>
					</clipPath>
				</defs>
			</svg>
		</Box>
	);
};