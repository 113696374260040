import {styled} from '@mui/material/styles';

export const Puller = styled('div')(() => ({
	width: 60,
	height: 4,
	backgroundColor: '#83828a',
	borderRadius: 3,
	position: 'absolute',
	top: 3,
	left: 'calc(50% - 30px)',
}));